.container {
  padding: 32px;
  background-color: #f5f5f5;
  min-height: calc(100vh - 64px);
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.titleSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-size: 32px;
  font-weight: 700;
  color: #2a3836;
  line-height: 1.2;
  margin: 0;
}

.subtitle {
  font-size: 16px;
  color: #666666;
  margin: 0;
}

.actionButtons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.iconButton {
  width: 42px !important;
  height: 42px !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #034832 !important;
  border-color: #034832 !important;
  color: white !important;
  transition: all 0.2s ease !important;
}

.iconButton:hover {
  background-color: #023324 !important;
  border-color: #023324 !important;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(3, 72, 50, 0.2);
}

.actionIcon {
  color: #034832;
  font-size: 18px;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.actionIcon:hover {
  color: #023324;
  background-color: rgba(3, 72, 50, 0.1);
}

.searchSection {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  align-items: center;
  flex-wrap: wrap;
}

.tableButton {
  height: 42px !important;
  background-color: #034832 !important;
  border-color: #034832 !important;
  color: white !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 0 24px !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: inline-flex !important;
  align-items: center !important;
  gap: 8px !important;
  transition: all 0.2s ease !important;
}

.tableButton:hover {
  background-color: #023324 !important;
  border-color: #023324 !important;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(3, 72, 50, 0.2);
}

.tableContainer {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0;
  margin-top: 16px;
}

/* Ant Design Table Overrides */
:global(.ant-table) {
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
}

:global(.ant-table-thead > tr > th) {
  background-color: #f8f9fa !important;
  padding: 16px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #4B5563 !important;
  border-bottom: 1px solid #e0e0e0 !important;
}

:global(.ant-table-tbody > tr > td) {
  padding: 16px !important;
  font-size: 14px !important;
  color: #2a3836 !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

:global(.ant-input) {
  border-radius: 8px !important;
  border: 1px solid #e0e0e0 !important;
  height: 42px !important;
  padding: 8px 16px !important;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
  font-size: 14px !important;
}

:global(.ant-input:hover) {
  border-color: #034832 !important;
}

:global(.ant-input:focus) {
  border-color: #034832 !important;
  box-shadow: 0 0 0 2px rgba(3, 72, 50, 0.1) !important;
}

:global(.ant-select:not(.ant-select-disabled):hover .ant-select-selector) {
  border-color: #034832 !important;
}

:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-color: #034832 !important;
  box-shadow: 0 0 0 2px rgba(3, 72, 50, 0.1) !important;
}

:global(.ant-btn-primary) {
  background-color: #034832 !important;
  border-color: #034832 !important;
}

:global(.ant-btn-primary:hover) {
  background-color: #023324 !important;
  border-color: #023324 !important;
}

:global(.ant-switch-checked) {
  background-color: #034832 !important;
}

:global(.ant-modal-content) {
  border-radius: 12px !important;
  padding: 24px !important;
}

:global(.ant-modal-header) {
  padding: 0 !important;
  margin-bottom: 24px !important;
}

:global(.ant-modal-title) {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #2a3836 !important;
}

.actionsCell {
  display: flex;
  gap: 8px;
  align-items: center;
}

@media (max-width: 768px) {
  .container {
    padding: 16px;
  }
  
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  
  .searchSection {
    flex-direction: column;
    width: 100%;
  }
  
  .actionButtons {
    flex-wrap: wrap;
  }
}
