.container {
  padding: 32px;
  background-color: #f5f5f5;
  min-height: calc(100vh - 64px);
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.titleSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-size: 32px;
  font-weight: 700;
  color: #2a3836;
  line-height: 1.2;
  margin: 0;
}

.subtitle {
  font-size: 16px;
  color: #666666;
  margin: 0;
}

.searchSection {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  align-items: center;
}

.searchBar {
  flex: 1;
  max-width: 400px;
}

.searchBar > div {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  transition: all 0.2s ease;
  height: 42px;
}

.searchBar input {
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
  height: 42px;
  padding: 8px 16px 8px 44px !important;
  font-size: 14px !important;
  border: none !important;
}

.searchBar svg {
  margin-left: 16px;
  color: #6B7280;
}

.searchBar > div:hover {
  border-color: #034832;
}

.searchBar > div.Mui-focused {
  border-color: #034832;
  box-shadow: 0 0 0 2px rgba(3, 72, 50, 0.1);
}

.addButton {
  height: 42px !important;
  background-color: #034832 !important;
  color: white !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 0 24px !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: inline-flex !important;
  align-items: center !important;
  gap: 8px !important;
  transition: all 0.2s ease !important;
}

.addButton:hover {
  background-color: #023324 !important;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.tableContainer {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.tableHead {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
}

.tableHeadCell {
  padding: 16px !important;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #4B5563 !important;
  white-space: nowrap !important;
  border-bottom: 1px solid #e0e0e0 !important;
  height: 56px !important;
  box-sizing: border-box !important;
  vertical-align: middle !important;
}

.sortIcon {
  opacity: 0.5;
  transition: opacity 0.2s;
}

.sortActive .sortIcon {
  opacity: 1;
  color: #034832;
}

.tableCell {
  padding: 16px !important;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif !important;
  font-size: 14px !important;
  color: #2a3836 !important;
  border-bottom: 1px solid #f0f0f0 !important;
  height: 56px !important;
  box-sizing: border-box !important;
  vertical-align: middle !important;
}

.clientNameCell {
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  color: #111827;
  font-size: 14px;
}

.contactCell {
  padding: 12px 0;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.contactCell .contactName {
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  color: #111827;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}

.contactCell .primaryContact {
  background: #E4F2EB;
  color: #034832;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.contactCell .contactInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #6B7280;
  font-size: 14px;
  padding: 4px 0;
  cursor: pointer;
  transition: color 0.2s;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.contactCell .contactInfo:hover {
  color: #034832;
}

.contactCell .noContact {
  color: #9CA3AF;
  font-style: italic;
  font-size: 0.875rem;
}

.metadataCell {
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.metadataBadge {
  display: flex;
  align-items: center;
  gap: 6px;
  background: #F3F4F6;
  color: #4B5563;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.actionsCell {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  min-height: 32px;
}

.actionGroup {
  display: flex;
  gap: 4px;
  align-items: center;
}

.actionButton {
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  padding: 6px !important;
  color: #6B7280 !important;
  border-radius: 6px !important;
  transition: all 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 !important;
}

.actionButton svg {
  width: 18px !important;
  height: 18px !important;
}

.actionButton:hover {
  background: #F3F4F6 !important;
  color: #034832 !important;
}

.actionButton.danger:hover {
  background: #FEE2E2 !important;
  color: #DC2626 !important;
}

.copiedTooltip {
  background: #034832;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  font-size: 0.75rem;
  pointer-events: none;
  z-index: 1000;
}

.filterBar {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.filterChip {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  color: #666666;
  background-color: white;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filterChip:hover {
  background-color: #f8f9fa;
  border-color: #034832;
  color: #034832;
}

.filterChip.active {
  background-color: #034832;
  border-color: #034832;
  color: white;
}

/* Modal Styles */
.modal {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.modalTitle {
  font-size: 24px;
  font-weight: 600;
  color: #2a3836;
  margin-bottom: 24px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formField {
  width: 100%;
}

.formField input {
  font-family: 'Plus Jakarta Sans', sans-serif;
  height: 42px;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  transition: all 0.2s ease;
}

.formField input:hover {
  border-color: #034832;
}

.formField input:focus {
  border-color: #034832;
  box-shadow: 0 0 0 2px rgba(3, 72, 50, 0.1);
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.cancelButton {
  height: 42px !important;
  background-color: transparent !important;
  color: #666666 !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px !important;
  padding: 0 24px !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  transition: all 0.2s ease !important;
}

.cancelButton:hover {
  background-color: #f5f5f5 !important;
}

.submitButton {
  height: 42px !important;
  background-color: #034832 !important;
  color: white !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 0 24px !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  transition: all 0.2s ease !important;
}

.submitButton:hover {
  background-color: #023324 !important;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Sites Modal Styles */
.sitesModal {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.sitesModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.sitesModalTitle {
  font-size: 24px;
  font-weight: 600;
  color: #2a3836;
}

.sitesSearchBar {
  margin-bottom: 24px;
}

.sitesList {
  max-height: 500px;
  overflow-y: auto;
}

.siteAccordion {
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px !important;
  margin-bottom: 12px !important;
  overflow: hidden !important;
}

.siteAccordionSummary {
  background-color: #f8f9fa !important;
  padding: 16px !important;
}

.siteAccordionDetails {
  padding: 16px !important;
  background-color: white !important;
}

/* Enhanced Contacts Modal Styles */
.contactsModal {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.contactsModal > div > div {
  border-radius: 16px;
  max-width: 800px;
  overflow: visible;
}

.contactsModalHeader {
  padding: 32px 32px 0;
  border-bottom: none;
  position: relative;
}

.contactsModalTitle {
  font-size: 28px;
  font-weight: 700;
  color: #111827;
  margin-bottom: 8px;
  line-height: 1.2;
}

.contactsModalSubtitle {
  color: #6B7280;
  font-size: 15px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.contactsToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 32px 24px;
  gap: 16px;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  padding-top: 24px;
  border-bottom: 1px solid #E5E7EB;
  padding-bottom: 24px;
}

.contactsSearchBar {
  flex: 1;
  min-width: 300px;
  position: relative;
  background: #F9FAFB;
  border-radius: 12px;
  transition: all 0.2s ease;
}

.contactsSearchBar:hover {
  background: #F3F4F6;
}

.contactsSearchBar input {
  width: 100%;
  height: 48px;
  padding: 8px 16px 8px 48px;
  border: 2px solid transparent;
  border-radius: 12px;
  font-family: inherit;
  font-size: 15px;
  background: transparent;
  color: #111827;
  transition: all 0.2s ease;
}

.contactsSearchBar input:focus {
  outline: none;
  border-color: #034832;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.contactsSearchBar input::placeholder {
  color: #9CA3AF;
}

.contactsSearchBar svg {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #9CA3AF;
  transition: color 0.2s ease;
}

.contactsSearchBar input:focus + svg {
  color: #034832;
}

.contactsList {
  margin: 0;
  padding: 0 32px 32px;
  max-height: 60vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #CBD5E1 transparent;
}

.contactsList::-webkit-scrollbar {
  width: 6px;
}

.contactsList::-webkit-scrollbar-track {
  background: transparent;
}

.contactsList::-webkit-scrollbar-thumb {
  background-color: #CBD5E1;
  border-radius: 3px;
}

.contactItem {
  padding: 20px !important;
  border: 2px solid #E5E7EB !important;
  border-radius: 12px !important;
  margin-bottom: 16px !important;
  transition: all 0.2s ease !important;
  background: white !important;
  position: relative !important;
  overflow: visible !important;
}

.contactItem:hover {
  border-color: #034832 !important;
  box-shadow: 0 4px 16px rgba(3, 72, 50, 0.08) !important;
  transform: translateY(-1px);
}

.contactHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.contactActions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.setPrimaryButton {
  height: 36px !important;
  padding: 0 16px !important;
  font-size: 14px !important;
  border: 2px solid #034832 !important;
  color: #034832 !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
  letter-spacing: -0.01em !important;
  transition: all 0.2s ease !important;
}

.setPrimaryButton:hover {
  background: rgba(3, 72, 50, 0.08) !important;
  transform: translateY(-1px) !important;
  box-shadow: 0 4px 12px rgba(3, 72, 50, 0.08) !important;
}

.contactItem .contactName {
  font-weight: 600;
  color: #111827;
  font-size: 17px;
  display: flex;
  align-items: center;
  gap: 12px;
  letter-spacing: -0.01em;
}

.contactItem .primaryBadge {
  background: #ECFDF5 !important;
  color: #059669 !important;
  padding: 6px 12px !important;
  border-radius: 6px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  display: inline-flex !important;
  align-items: center !important;
  gap: 6px !important;
  letter-spacing: -0.01em !important;
}

.contactItem .contactInfo {
  background: #F9FAFB;
  border-radius: 10px;
  padding: 16px;
}

.contactItem .contactDetail {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4B5563;
  font-size: 15px;
  padding: 6px 0;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.contactItem .contactDetail:hover {
  color: #034832;
}

.contactItem .contactDetail::after {
  content: "Click to copy";
  position: absolute;
  right: 0;
  color: #9CA3AF;
  font-size: 13px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.contactItem .contactDetail:hover::after {
  opacity: 1;
}

.contactItem .contactNotes {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #E5E7EB;
  font-size: 14px;
  color: #6B7280;
  line-height: 1.5;
  white-space: pre-wrap;
}

.emptyContacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 32px;
  text-align: center;
  background: #F9FAFB;
  border-radius: 12px;
  margin: 0 32px 32px;
}

.emptyContactsIcon {
  color: #034832;
  margin-bottom: 24px;
  opacity: 0.9;
}

.emptyContactsText {
  color: #6B7280;
  margin-bottom: 32px;
  font-size: 16px;
  max-width: 400px;
  line-height: 1.5;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .searchSection {
    flex-direction: column;
    width: 100%;
  }

  .searchBar {
    max-width: 100%;
  }

  .addButton {
    width: 100%;
  }

  .tableContainer {
    border-radius: 0;
  }

  .metadataCell {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }

  .actionsCell {
    flex-direction: column;
    align-items: flex-end;
  }
}

/* Loading States */
.loadingOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.loadingSpinner {
  color: #034832 !important;
}

/* Empty States */
.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  color: #666666;
}

.emptyStateIcon {
  color: #034832;
  margin-bottom: 16px;
  font-size: 48px;
}

.emptyStateText {
  font-size: 16px;
  margin-bottom: 16px;
}

/* Contact Modal Specific Styles */
.contactItem .contactName {
  font-size: 16px;
  color: #2a3836;
}

.contactItem .contactInfo {
  background: #f8f9fa;
  border-radius: 6px;
  padding: 12px;
  display: block;
}

.contactDetail {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #4B5563;
  font-size: 14px;
  padding: 4px 0;
  cursor: pointer;
  transition: color 0.2s;
}

.contactDetail:hover {
  color: #034832;
} 